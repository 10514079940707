<template>
    <b-row>
        <div>
            <b-button variant="primary" @click="pdfExport" class="mr-2 float-right">
          {{  $t('globalTrans.export_pdf') }}
        </b-button>
        </div>
      <b-col lg="12" sm="12">
        <body-card>
          <template>
            <b-overlay :show="loader">
                <b-container>
                    <div class="text-center">
                        <h3>Letter of Consent</h3>
                    </div>
                    <div>
                        Director <br>
                        Fair and Display Division <br>
                        Export Promotion Bureau <br>
                        <br>
                        Dear Sir,<br>

                        We have reserved a booth in the Bangladesh pavilion through the Export Promotion Bureau (EPB) for
                        the upcoming edition of {{ circular.calendar_info?.fair_name }}, tscheduled to take place from {{ circular.calendar_info?.date_from }} to {{ circular.calendar_info?.date_from}} in {{ circular.calendar_info?.city }}, {{ getCountryName(circular.calendar_info?.country_id) }}.
                        We kindly request your assistance in providing us with a visa invitation letter, in compliance with the
                        following terms and conditions: <br>
                        <p class="ml-5">
                            1. We acknowledge and agree that the Exspanort Promotion Bureau (EPB) facilitates exhibitors by
                            issuing visa invitation letters solely to those who have received approval from the competent
                            authority of the Export Promotion Bureau (EPB).
                        </p>
                        <br>
                        <p class="ml-5">
                            2. We understand and agree that the decision to grant or reject a visa rests solely with the Canadian
                            High Commission. Neither EPB, the show management/authority, nor their authorized agent in
                            Bangladesh has any control over this process.
                        </p><br>
                        <p class="ml-5">
                            3. We understand and agree that the EPB/fair authority or its authorized agent in Bangladesh does
                            not have the authority to schedule appointments, submit passports, or receive decisions from the
                            VFS or the relevant High Commission/Embassy.
                        </p>
                            <br>
                        <p class="ml-5">
                            4. We understand and agree that visa processing times may be longer than usual. Neither EPB nor
                            the concerned show management or its authorized agent in Bangladesh have any control over the
                            processing time.
                        </p><br>
                        <p class="ml-5">
                            5. We understand and agree that {{ circular.calendar_info?.fair_name }} is an international exhibition, and in the event of
                            visa delays affecting international exhibitors worldwide, the show management may consider
                            revising the exhibition dates to a more suitable time. Should this occur, we commit to participating
                            accordingly. We acknowledge that no compensation or refund will be provided to us if the
                            exhibition dates are changed.
                        </p><br>
                        We kindly request the visa invitation letter to be issued at your earliest convenience, as we need to initiate
                        the visa application process promptly. <br> <br>
                        Thank you for your attention to this matter. We look forward to your favorable response.
                        <br>
                        <br>
                        <img class="" style="width: 120px" :src="baseUrl + 'storage/' + detailsData.consent_per_signature" alt="Signature">
                        <br>
                        {{ detailsData.consent_per_name }} <br>
                        {{ detailsData.consent_per_designation }} <br>
                        {{ detailsData.name_en }} <br>
                        <br>
                        Note: This letter is a sample and should be customized according to your specific requirements.
                    </div>
                </b-container>
            </b-overlay>
          </template>
        </body-card>
      </b-col>
    </b-row>
</template>
<script>
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import { fairPartListDetailsApi, fairInfoByCircularApi, fairConsentPaperPdfApi } from '../../api/routes'
export default {
    name: 'Details',
    props: ['id'],
    components: {
    },
    data () {
      return {
        baseUrl: internationalTradeFairServiceBaseUrl,
        loader: false,
        detailsData: [],
        circular: {}
      }
    },
    created () {
      if (this.id) {
        this.getParticipationInfo()
      }
    },
    methods: {
      BangNum (n) {
         if (this.$i18n.locale === 'bn') {
            return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
         }
         return n
      },
      getCountryName (Id) {
        const Obj = this.$store.state.CommonService.commonObj.countryList.find(item => item.value === parseInt(Id))
        if (typeof Obj !== 'undefined') {
            return this.$i18n.locale === 'bn' ? Obj.text_bn : Obj.text_en
        } else {
          return ''
        }
      },
      async getParticipationInfo () {
        this.loader = true
        const resultData = await RestApi.getData(internationalTradeFairServiceBaseUrl, fairPartListDetailsApi, { app_id: this.id })
        if (resultData.success) {
          this.detailsData = resultData.data
          this.getStallByCircularIdInfo(this.detailsData.fair_circular_id)
          this.loader = false
        } else {
          this.detailsData = []
          this.loader = false
        }
      },
      async getStallByCircularIdInfo (circularId) {
        this.loader = true
        const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, fairInfoByCircularApi, { circular_id: circularId })
        if (result.success) {
          const fData = result.data
          if (fData) {
            this.circular = fData.circular
          }
        }
        this.loader = false
      },
      async pdfExport () {
        this.loader = true
        const params = Object.assign({ local: 'en', org_id: 2, id: this.id, app_id: this.id })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const result = await RestApi.getPdfData(internationalTradeFairServiceBaseUrl, fairConsentPaperPdfApi, params)
        var blob = new Blob([result], {
          type: 'application/pdf'
        })
        var url = window.URL.createObjectURL(blob)
        window.open(url).print()
        this.loader = false
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      }
    }
}
</script>
<style>
.download-btn {
  color: green;
  border: 1px solid gray;
  margin-left: 5px;
  font-size: 18px;
  padding: 1px 3px;
}

.yes-btn {
  color: green;
  border: 1px solid gray;
  margin-left: 5px;
  font-size: 18px;
  font-weight: 700;
  padding: 1px 3px;
}
</style>
